.loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background: #ffffff;
  display: grid;
  place-items: center;
}
