.panel {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 1rem;
}

.panel .panel_cont {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.panel .panel_cont .dashboard {
  margin-left: auto;
}

.panel .panel_cont .dashboard.panel_move {
  width: calc(100% - 236px);
}

.panel .panel_cont .dashboard.panel_no_move {
  width: 100%;
}

.dashboard::-webkit-scrollbar {
  display: none;
}

.dashboard {
  transition: all 0.3s ease-in-out;
  overflow-y: hidden;
}

.dashboard .dashboard_cont {
  margin-top: 14px;
  height: calc(100% - 70px);
  overflow: scroll;
}

.dashboard .dashboard_cont::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1024px) {
  .panel .panel_cont .dashboard {
    width: 100% !important;
  }

  .panel .panel_cont .dashboard.panel_move {
    transform: translateX(236px);
  }

  .panel .panel_cont .dashboard.panel_no_move {
    transform: translateX(0);
  }
}