.product_rating .bi {
  margin-right: 2px;
}

.product_rating .star_on {
  color: #ffb33e;
}

.product_rating .star_off {
  color: #818895;
}
