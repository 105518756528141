.preview_rtl .card_hours_wrapper .card_hour,
.preview_rtl .card_hours_wrapper .card_hour > div {
  flex-direction: row-reverse;
}

.rtl .header_cont,
.rtl .header_right,
.rtl .usermenu .usermenu_head,
.rtl .custom_modal_head,
.rtl .builder_cont,
.rtl .card_design .c_head,
.rtl .design_wrapper > div:first-child,
.rtl .gallery_wrapper .gw1,
.rtl .floating_number_wrapper,
.rtl .gen_setting,
.rtl .design_wrapper.hours_wrapper .main_icon_inputs {
  flex-direction: row-reverse !important;
}

.rtl .auth_child2,
.rtl .profile_page_cont .title_3,
.rtl .usermenu .usermenu_head,
.rtl .username_links,
.rtl .sidebar .link,
.rtl .custom_modal_body input,
.rtl .custom_modal_body textarea,
.rtl .custom_modal_body select,
.rtl .design_wrapper.hours_wrapper > p,
.rtl input {
  text-align: end !important;
}

.preview_rtl .card_reviews .card_title,
.rtl .card_reviews .card_title {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 6px;
}

.rtl .sidebar .link.active::before {
  right: 0 !important;
  left: auto;
}

.rtl .PhoneInput {
  flex-direction: row-reverse;
  gap: 8px;
}

.rtl .design_input_wrapper label,
.rtl .custom_modal_body label {
  display: block;
  text-align: end;
}

.rtl .sidebar.toggleShow {
  left: auto;
  right: 1rem !important;
}

.rtl .sidebar.toggleHide {
  left: auto;
  right: -280px !important;
}

.rtl .panel .panel_cont .dashboard {
  margin-left: 0;
  margin-right: auto;
}

.rtl .usermenu {
  right: auto;
  left: 0;
}

.rtl .gallery_wrapper .gw2,
.rtl .design_wrapper .sorting_select {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 5px;
}

.rtl .gallery_wrapper .gw2 select,
.rtl .design_wrapper .sorting_select select {
  margin-left: 0;
}

.rtl .profile_form button {
  margin-left: auto;
}

.rtl .password_form .profile_input_wrapper {
  justify-content: flex-end;
}

@media screen and (max-width: 1024px) {
  .rtl .panel .panel_cont .dashboard.panel_move {
    transform: translateX(-236px);
  }
}
