.usermenu {
  position: absolute;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  width: 275px;
  border-radius: 6px;
  z-index: 5;
}

.usermenu > div {
  padding: 10px 16px;
}

.usermenu .usermenu_head {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-bottom: 1px solid #eff2f5;
}

.usermenu .usermenu_head img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
}

.usermenu .usermenu_head span {
  font-size: 14px;
  color: var(--body_text);
}

.username_links {
  margin-top: 10px;
}

.username_links a {
  display: block;
  padding: 8px;
  transition: all 0.3s ease-in-out;
  border-radius: var(--border_radius);
}

.username_links a:hover {
  background-color: rgb(37 99 235 / 10%);
  color: var(--primary);
}

.username_links a .bi::before {
  font-weight: 600 !important;
  font-size: 20px;
}
