.auth_page {
  width: 100%;
  height: 100vh;
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.auth_page .auth_cont {
  width: 400px;
  /* aspect-ratio: 16 / 9; */
  background-color: #ffffff;
  border-radius: var(--border_radius);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.auth_page .auth_cont > div {
  width: 100%;
  height: 100%;
}

.auth_cont .auth_child1 {
  border-right: 1px solid lightgray;
}

.auth_cont .auth_child1,
.auth_cont .auth_child2 {
  padding: 1.5rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.auth_form {
  font-size: 14px;
}

.c_input_wrapper {
  margin-bottom: 14px;
}

.auth_form .form_link {
  display: block;
}

.auth_form .form_link span {
  color: var(--primary);
}

.auth_form .btn_primary {
  width: 100%;
  display: block;
  margin: 1.5rem 0;
}

.title_1 {
  font-size: 24px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
}
