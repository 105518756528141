.preview_page {
  max-width: 550px;
  margin: 0 auto;
}

.card_preview {
  overflow-y: scroll;
  margin-left: auto;
  padding-bottom: 0;
  position: relative;
  box-shadow: 0px 0px 20px 0px #656565a6;
}

.preview_page .card_preview {
  min-height: 100vh;
}

.preview_page .card_floating {
  /* position: absolute;
  bottom: 0; */
  transform: translateY(-15px);
}

.preview_page .copyright {
  margin-top: 0 !important;
}

.card_preview_move {
  padding: 14px;
}

.card_preview.card_preview_width {
  width: calc(100% - 350px - 1rem);
}

.card_preview::-webkit-scrollbar {
  display: none;
}

.cover_image_wrapper {
  padding: 14px 14px 0 14px;
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
}

.cover_image_wrapper img {
  border-radius: var(--border_radius);
}

.card_logo_wrapper {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto;
  padding: 2px;
}

.card_logo_wrapper img,
.cover_image_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.like_wrapper {
  background: #1978ee;
  border-radius: 6px;
  color: white;
  width: fit-content;
  padding: 3.5px 6.3px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  position: absolute;
  bottom: 10px;
  left: 24px;
  z-index: 10;
  cursor: pointer;
}

.card_title {
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  margin: 10px 0;
}

.card_subtitle {
  text-align: center;
}

.card_hours_wrapper .card_hour {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_hours_wrapper .card_hour:not(:last-child) {
  border-bottom: 1px solid;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.card_hours_wrapper .card_hour > p {
  font-weight: 500;
}

.card_hours_wrapper .card_hour > div {
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: 135px;
}

.card_hours_wrapper .card_hour > div p {
  font-size: 14px;
}

.card_hours_wrapper .card_hour span {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
}

.card_hours_wrapper .card_hour span.off {
  background-color: red;
}

.card_hours_wrapper .card_hour span.on {
  background-color: rgba(77, 218, 41, 1);
}

.card_button {
  background-color: transparent;
  outline: 0;
  border: 0;
  font-size: 14px;
  margin: 0 auto;
  cursor: pointer;
  display: block;
  color: inherit;
}

.card_floating {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 1.5rem;
  width: 100%;
}

.card_floating .card_button {
  margin: 0;
}

.floating_btn {
  position: absolute;
  left: calc(50% - 20px);
  border-radius: 100%;
  width: 60px;
  height: 60px;
  padding: 5px;
  transform: translateY(-16px);
}

.floating_btn a,
.floating_btn img {
  width: 100%;
  height: 100%;
}

.card_gallery,
.card_icons_slider {
  text-align: center;
  margin-top: 1rem;
  padding: 0 4rem;
  /* display: flex; */
  justify-content: center;
}

.card_gallery img {
  width: 80%;
  aspect-ratio: 1/1;
  border-radius: 4px;
  object-fit: cover;
  cursor: pointer;
}

.react-multi-carousel-list {
  padding-bottom: 16px;
}

.custom_modal_body .react-multi-carousel-list {
  padding-bottom: 0;
}

.card_reviews .react-multi-carousel-list {
  padding-bottom: 18px;
}

.react-multi-carousel-dot-list {
  bottom: -2px;
}

.react-multiple-carousel__arrow {
  min-width: 24px !important;
  min-height: 24px !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(4% + -12px) !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + -12px);
}

@media screen and (max-width: 800px) {
  .cover_image_wrapper {
    height: 200px;
  }

  .card_preview.card_preview_width {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .card_gallery,
  .card_icons_slider {
    padding: 0;
  }
}
