.custom_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  background: rgb(86 86 86 / 20%);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.custom_modal_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #3e3e3e;
  padding-bottom: 8px;
  margin-bottom: 8px;
  font-weight: 500;
}
