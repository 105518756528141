.dashboard_cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 130px);
  gap: 1rem;
}

.dashboard_cards .c_card {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 2rem;
}

.dashboard_cards .card_icon {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  padding: 5px;
  display: grid;
  place-items: center;
}

.dashboard_cards .card_icon.red {
  background: rgba(255, 83, 116, 0.1);
}

.dashboard_cards .card_icon.green {
  background: rgba(37, 99, 235, 0.1);
}

.dashboard_cards .card_icon.golden {
  background: rgba(255, 187, 68, 0.1);
}

.dashboard_cards .c_card p {
  font-size: 24px;
  font-weight: 700;
}

.dashboard_cards .c_card span {
  color: var(--body_text);
}

.dashboard_graphs,
.dashboard_tables {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.dashboard_graphs > div:first-child {
  width: calc(100% - 400px);
}

.dashboard_graphs > div:last-child {
  width: 400px;
}

.dashboard_tables > div:first-child {
  width: calc(100% - 450px);
}

.dashboard_tables > div:last-child {
  width: 450px;
}

.dashboard_table {
  overflow-x: auto;
}

.dashboard_table table {
  width: 100%;
  text-align: justify;
  margin-top: 5px;
  font-size: 14px;
}

.dashboard_table.p tbody td {
  padding: 5px 0;
}

.dashboard_table .status {
  display: block;
  padding: 2px 6px;
  width: fit-content;
  font-weight: 600;
  border-radius: 6px;
  font-size: 13px;
}

.dashboard_table .status.active {
  background-color: #2563eb33;
  color: var(--primary);
}

.dashboard_table .status.expired {
  background-color: #ff537433;
  color: #ff5374;
}

.dashboard_table .table_image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
}

@media screen and (max-width: 768px) {
  .dashboard_cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard_graphs,
  .dashboard_tables {
    flex-direction: column;
  }

  .dashboard_graphs > div,
  .dashboard_tables > div {
    width: 100% !important;
  }
}

@media screen and (max-width: 575px) {
  .dashboard_cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
