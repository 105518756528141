.card_design {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.card_design .c_head {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card_design .c_head span {
  transition: all 0.15s ease-in-out;
}

.card_design .c_head .bi::before {
  color: var(--primary);
}

.card_design .c_body {
  border-top: 5px solid var(--primary);
  margin-top: 5px;
  padding-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.card_design .bi {
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.card_design .bi.bi-trash3 {
  color: red;
}

.card_design .bi.bi-pencil-square {
  color: rgb(0, 68, 255);
}

.card_design .bi:hover {
  opacity: 0.8;
}

.card_design .bi::before {
  font-weight: 600 !important;
}
