.builder_cont {
  height: 100%;
  display: flex;
  gap: 1rem;
}

.builder_cont > div {
  height: 100%;
}

.builder_options {
  width: 350px;
}

.builder_options {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: 100%;
  overflow-y: scroll;
}

.builder_options::-webkit-scrollbar {
  display: none;
}

/* design */
.design_input_wrapper {
  margin-top: 3px;
}

.design_input_wrapper label {
  /* font-weight: 500; */
}

.design_input_wrapper input {
  display: block;
  margin-top: 2px;
  width: 100%;
  border: 0;
}

.design_input_wrapper.number input,
.design_input_wrapper input[type="number"],
.design_input_wrapper input[type="text"] {
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  padding: 8px;
}

.design_input_wrapper input[type="file"] {
  margin-top: 5px;
  width: auto;
}

/* Main Icons */
.design_wrapper {
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  padding: 10px 10px 5px 10px;
  margin-top: 4px;
}

.design_wrapper > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
}

.design_wrapper .sorting_select {
  font-size: 12px;
  text-align: end;
  margin-top: 4px;
}

.design_wrapper .sorting_select select {
  font-weight: 500;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 10px;
}

.icon_wrapper {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
}

.icon_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.design_wrapper input:last-child {
  margin-top: 8px;
}

.design_wrapper input:first-child {
  margin-top: 0px;
}

.design_wrapper input {
  border-radius: 5px;
  border: 1px solid #d4d4d4;
  padding: 8px;
  width: 100%;
}

/* opening hours  */
.hours_wrapper input {
  font-size: 16px;
  padding: 3px 8px;
}

/* gallery wrapper  */
.gallery_wrapper .gw1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gallery_wrapper .gw2 {
  font-size: 12px;
  text-align: end;
}

.gallery_wrapper .gw2 select {
  font-weight: 500;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 10px;
}

.gallery_wrapper img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.galley_actions {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.galley_actions input[type="file"] {
  display: none;
}

.icons_cont {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y: scroll;
  justify-content: center;
}

.icons_cont::-webkit-scrollbar {
  display: none;
}

.icons_cont img {
  cursor: pointer;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

@media screen and (max-width: 800px) {
  .builder_cont {
    flex-direction: column;
    height: auto;
  }

  .builder_options {
    width: 100%;
  }
}
