.card_reviews .review_wrapper {
  height: 100%;
}

.review_wrapper {
  position: relative;
  overflow: hidden;
  padding: 5px 10px;
  border-radius: 8px;
}

.card_design .review_wrapper {
  background: transparent !important;
  border: 1px solid #d4d4d4;
}

.review_wrapper .review_head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: 500;
}

.review_wrapper .review_head span {
  font-size: 12px;
  font-weight: 400;
  color: #c0c0c0;
}

.review_wrapper .review_actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
